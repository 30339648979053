// poppins font start
@font-face {
   font-family: 'Poppins';
   src: url('/assets/fonts/Poppins/Poppins-Thin.woff2') format('woff2'),
      url('/assets/fonts/Poppins/Poppins-Thin.woff') format('woff');
   font-weight: 100;
   font-style: normal;
   font-display: swap;
}

@font-face {
   font-family: 'Poppins';
   src: url('/assets/fonts/Poppins/Poppins-ThinItalic.woff2') format('woff2'),
      url('/assets/fonts/Poppins/Poppins-ThinItalic.woff') format('woff');
   font-weight: 100;
   font-style: italic;
   font-display: swap;
}

@font-face {
   font-family: 'Poppins';
   src: url('/assets/fonts/Poppins/Poppins-ExtraLight.woff2') format('woff2'),
      url('/assets/fonts/Poppins/Poppins-ExtraLight.woff') format('woff');
   font-weight: 200;
   font-style: normal;
   font-display: swap;
}

@font-face {
   font-family: 'Poppins';
   src: url('/assets/fonts/Poppins/Poppins-ExtraLightItalic.woff2') format('woff2'),
      url('/assets/fonts/Poppins/Poppins-ExtraLightItalic.woff') format('woff');
   font-weight: 200;
   font-style: italic;
   font-display: swap;
}

@font-face {
   font-family: 'Poppins';
   src: url('/assets/fonts/Poppins/Poppins-Light.woff2') format('woff2'),
      url('/assets/fonts/Poppins/Poppins-Light.woff') format('woff');
   font-weight: 300;
   font-style: normal;
   font-display: swap;
}

@font-face {
   font-family: 'Poppins';
   src: url('/assets/fonts/Poppins/Poppins-LightItalic.woff2') format('woff2'),
      url('/assets/fonts/Poppins/Poppins-LightItalic.woff') format('woff');
   font-weight: 300;
   font-style: italic;
   font-display: swap;
}

@font-face {
   font-family: 'Poppins';
   src: url('/assets/fonts/Poppins/Poppins-Regular.woff2') format('woff2'),
      url('/assets/fonts/Poppins/Poppins-Regular.woff') format('woff');
   font-weight: normal;
   font-style: normal;
   font-display: swap;
}

@font-face {
   font-family: 'Poppins';
   src: url('/assets/fonts/Poppins/Poppins-Italic.woff2') format('woff2'),
      url('/assets/fonts/Poppins/Poppins-Italic.woff') format('woff');
   font-weight: normal;
   font-style: italic;
   font-display: swap;
}

@font-face {
   font-family: 'Poppins';
   src: url('/assets/fonts/Poppins/Poppins-Medium.woff2') format('woff2'),
      url('/assets/fonts/Poppins/Poppins-Medium.woff') format('woff');
   font-weight: 500;
   font-style: normal;
   font-display: swap;
}

@font-face {
   font-family: 'Poppins';
   src: url('/assets/fonts/Poppins/Poppins-MediumItalic.woff2') format('woff2'),
      url('/assets/fonts/Poppins/Poppins-MediumItalic.woff') format('woff');
   font-weight: 500;
   font-style: italic;
   font-display: swap;
}

@font-face {
   font-family: 'Poppins';
   src: url('/assets/fonts/Poppins/Poppins-SemiBold.woff2') format('woff2'),
      url('/assets/fonts/Poppins/Poppins-SemiBold.woff') format('woff');
   font-weight: 600;
   font-style: normal;
   font-display: swap;
}

@font-face {
   font-family: 'Poppins';
   src: url('/assets/fonts/Poppins/Poppins-SemiBoldItalic.woff2') format('woff2'),
      url('/assets/fonts/Poppins/Poppins-SemiBoldItalic.woff') format('woff');
   font-weight: 600;
   font-style: italic;
   font-display: swap;
}

@font-face {
   font-family: 'Poppins';
   src: url('/assets/fonts/Poppins/Poppins-Bold.woff2') format('woff2'),
      url('/assets/fonts/Poppins/Poppins-Bold.woff') format('woff');
   font-weight: bold;
   font-style: normal;
   font-display: swap;
}

@font-face {
   font-family: 'Poppins';
   src: url('/assets/fonts/Poppins/Poppins-ExtraBoldItalic.woff2') format('woff2'),
      url('/assets/fonts/Poppins/Poppins-ExtraBoldItalic.woff') format('woff');
   font-weight: bold;
   font-style: italic;
   font-display: swap;
}

@font-face {
   font-family: 'Poppins';
   src: url('/assets/fonts/Poppins/Poppins-ExtraBold.woff2') format('woff2'),
      url('/assets/fonts/Poppins/Poppins-ExtraBold.woff') format('woff');
   font-weight: 800;
   font-style: normal;
   font-display: swap;
}

@font-face {
   font-family: 'Poppins';
   src: url('/assets/fonts/Poppins/Poppins-BoldItalic.woff2') format('woff2'),
      url('/assets/fonts/Poppins/Poppins-BoldItalic.woff') format('woff');
   font-weight: 800;
   font-style: italic;
   font-display: swap;
}

@font-face {
   font-family: 'Poppins';
   src: url('/assets/fonts/Poppins/Poppins-Black.woff2') format('woff2'),
      url('/assets/fonts/Poppins/Poppins-Black.woff') format('woff');
   font-weight: 900;
   font-style: normal;
   font-display: swap;
}

@font-face {
   font-family: 'Poppins';
   src: url('/assets/fonts/Poppins/Poppins-BlackItalic.woff2') format('woff2'),
      url('/assets/fonts/Poppins/Poppins-BlackItalic.woff') format('woff');
   font-weight: 900;
   font-style: italic;
   font-display: swap;
}

// poppins font end

//Icon Font Start
@font-face {
   font-family: 'icomoon';
   src: url('/assets/fonts/icomoon.eot?b84shj');
   src: url('/assets/fonts/icomoon.eot?b84shj#iefix') format('embedded-opentype'),
      url('/assets/fonts/icomoon.ttf?b84shj') format('truetype'),
      url('/assets/fonts/icomoon.woff?b84shj') format('woff'),
      url('/assets/fonts/icomoon.svg?b84shj#icomoon') format('svg');
   font-weight: normal;
   font-style: normal;
   font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
   /* use !important to prevent issues with browser extensions that change fonts */
   font-family: 'icomoon' !important;
   speak: never;
   font-style: normal;
   font-weight: normal;
   font-variant: normal;
   text-transform: none;
   line-height: 1;

   /* Better Font Rendering =========== */
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
}



.icon-caret-up:before {
   content: "\e907";
}

.icon-caret-down:before {
   content: "\e926";
}

.icon-edit-outline:before {
   content: "\e900";
}

.icon-drag:before {
   content: "\e901";
}

.icon-info:before {
   content: "\e902";
}

.icon-filter:before {
   content: "\e903";
}

.icon-edit:before {
   content: "\e904";
}

.icon-iconDax:before {
   content: "\e905";
}

.icon-iconEaVehicle:before {
   content: "\e906";
}

.icon-iconHDYield:before {
   content: "\e908";
}

.icon-iconHealthcare:before {
   content: "\e909";
}

.icon-iconIbex35:before {
   content: "\e90a";
}

.icon-iconIDXComposite:before {
   content: "\e90b";
}

.icon-iconNasdaq:before {
   content: "\e90c";
}

.icon-iconPharmaceuticals:before {
   content: "\e90d";
}

.icon-iconRealState:before {
   content: "\e90e";
}

.icon-iconRegionalETFs:before {
   content: "\e90f";
}

.icon-iconRetail:before {
   content: "\e910";
}

.icon-iconSemiconducto:before {
   content: "\e911";
}

.icon-iconSP500:before {
   content: "\e912";
}

.icon-iconSPTSK:before {
   content: "\e913";
}

.icon-iconTadawulAllShare:before {
   content: "\e914";
}

.icon-iconTechnology:before {
   content: "\e915";
}

.icon-iconUpcomingEarings:before {
   content: "\e916";
}

.icon-iconUtilities:before {
   content: "\e917";
}

.icon-iconIndustrials:before {
   content: "\e918";
}

.icon-iconAutomobiles:before {
   content: "\e919";
}

.icon-iconBank:before {
   content: "\e91a";
}

.icon-iconBiotechnology:before {
   content: "\e91b";
}

.icon-iconBist100:before {
   content: "\e91c";
}

.icon-iconBseSensex:before {
   content: "\e91d";
}

.icon-iconCAC40:before {
   content: "\e91e";
}

.icon-iconCommunications:before {
   content: "\e91f";
}

.icon-iconConsumerGoods:before {
   content: "\e920";
}

.icon-iconCyclical:before {
   content: "\e921";
}

.icon-iconDividentStock:before {
   content: "\e922";
}

.icon-DJ:before {
   content: "\e923";
}

.icon-iconEducation:before {
   content: "\e924";
}

.icon-iconEnergy:before {
   content: "\e925";
}

.icon-iconETFs:before {
   content: "\e927";
}

.icon-iconEuroStoxx50:before {
   content: "\e928";
}

.icon-iconFinancial:before {
   content: "\e929";
}

.icon-iconFTSE100:before {
   content: "\e92a";
}

.icon-star-outline:before {
   content: "\e92b";
}

.icon-up-arrow:before {
   content: "\e92c";
}

.icon-search:before {
   content: "\e92d";
}

.icon-down-arrow:before {
   content: "\e92e";
}

.icon-drop-arrow:before {
   content: "\e92f";
}

.icon-sort:before {
   content: "\e930";
}

.icon-lock:before {
   content: "\e931";
}

.icon-question:before {
   content: "\e932";
}

.icon-sort-down:before {
   content: "\e933";
}

.icon-search2:before {
   content: "\e934";
}

.icon-calendar:before {
   content: "\e935";
}

.icon-sort-up:before {
   content: "\e936";
}

.icon-dotmenu:before {
   content: "\e937";
}

.icon-star-fill:before {
   content: "\e938";
}

.icon-globe:before {
   content: "\e939";
}

.icon-download:before {
   content: "\e93a";
}

.icon-delete:before {
   content: "\e93b";
}

.icon-link:before {
   content: "\e93c";
}

.icon-refresh:before {
   content: "\e93d";
}

.icon-user:before {
   content: "\e93e";
}

.icon-check:before {
   content: "\e93f";
}

.icon-plus:before {
   content: "\e940";
}

.icon-cross:before {
   content: "\e941";
}