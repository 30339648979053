@import "breakpoints";

//font weights
.fw-300 {
   font-weight: 300;
}

.fw-400 {
   font-weight: 400;
}

.fw-500 {
   font-weight: 500;
}

.fw-600 {
   font-weight: 600;
}

.fw-700 {
   font-weight: 700;
}

.fw-800 {
   font-weight: 800;
}

.fw-900 {
   font-weight: 900;
}

.w-10 {
   width: 10%;
}

.w-20 {
   width: 20%;
}

.w-30 {
   width: 30%;
}

.w-40 {
   width: 40%;
}

.w-50 {
   width: 50%;
}

.w-60 {
   width: 60%;
}

.w-70 {
   width: 70%;
}

.w-80 {
   width: 80%;
}

.w-90 {
   width: 90%;
}

.w-100 {
   width: 100%;
}

.height-5r {
   height: 5rem;
}

.height-10r {
   height: 10rem;
}

.height-20r {
   height: 20rem;
}

.height-30r {
   height: 30rem;
}

.height-40r {
   height: 40rem;
}

.height-50r {
   height: 50rem;
}

.content-padding {
   padding-block: 2.5rem;

   @include lg {
      padding-block: 3.125rem;
   }
}

.page-main-heading {
   font-weight: 600;
   font-size: 1.25rem;

   @include lg {
      font-size: 1.75rem;
   }
}

.main-heading {
   font-weight: 600;
   font-size: 1.5rem;

   @include lg {
      font-size: 2rem;
   }

   @include xl {
      font-size: 2.5rem;
   }
}

.section-main-heading {
   font-size: clamp(1.75rem, 1.4163rem + 1.4239vw, 3.125rem);
   color: #000;
   font-weight: 600;
   margin-bottom: 1.5rem;
   line-height: 1.3;

   @include lg {
      margin-bottom: 2.5rem;
   }
}

.view-all-btn {
   font-size: clamp(0.875rem, 0.8447rem + 0.1294vw, 1rem);
   color: #353535;
   font-weight: 500;
   //background: linear-gradient(white, white) padding-box, linear-gradient(to right, #385F93, #353535) border-box;
   border-radius: 0.325rem;
   border: 2px solid transparent;
   max-width: max-content;

   .arrow-icon {
      color: #1dac53;
   }
}

.gradient-btn {
   display: flex;
   align-items: center;
   gap: 0.75rem;
   font-weight: 500;
   background-image: linear-gradient(90deg, #2aa4bd 0%, #1dae51 100%);
   max-width: max-content;
   color: white;
   font-size: 0.875rem;

   @include lg {
      font-size: 1rem;
   }
}

.gradient-bg-btn {
   position: relative;
   background-image: linear-gradient(90deg, #29a3bd 0%, #1cad52 100%);
   z-index: 1;
   overflow: hidden;

   &:after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
      opacity: 0;
      transition: opacity 0.4s ease-in-out;
      background-image: linear-gradient(90deg, #1cad52 0%, #29a3bd 100%);
   }

   &:hover::after {
      opacity: 1;
   }
}

.gradient-border {
   background: linear-gradient(white, white) padding-box,
      linear-gradient(to right, #385f93, #1fab56) border-box;
   border: 2px solid transparent;
   max-width: max-content;
}

.gradient-text {
   background: linear-gradient(90deg, #2aa7b5 0%, #1faf58 100%);
   background-clip: text;
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
}

.gradient-btn-and-text {
   border: none;
   outline: none;
   position: relative;
   z-index: 1;
   border-radius: 0.375rem;
   background-image: linear-gradient(90deg, #2aa7b5 0%, #1faf58 100%);
   cursor: pointer;

   &:hover {
      &::before {
         opacity: 50%;
         inset: 0;
      }
   }

   &:hover {
      &::after {
         color: #fff;
      }
   }

   &::before {
      content: "";
      position: absolute;
      inset: 2px;
      border-radius: 0.25rem;
      background-color: white;
      z-index: -1;
      transition: 200ms;
   }

   &::after {
      content: attr(data);
      font-size: 0.875rem;
      font-weight: 500;
      background-image: linear-gradient(90deg, #2aa7b5 0%, #1faf58 100%);
      -webkit-background-clip: text;
      color: transparent;
      transition: 200ms;

      @include lg {
         font-size: 1rem;
      }
   }
}

.br-10 {
   border-radius: 0.625rem;
}

.line-clamp {
   display: -webkit-box;
   -webkit-box-orient: vertical;
   overflow: hidden;
   word-break: normal;
}

.line-clamp-1 {
   @extend .line-clamp;
   line-clamp: 1;
   -webkit-line-clamp: 1;
}

.line-clamp-2 {
   @extend .line-clamp;
   line-clamp: 2;
   -webkit-line-clamp: 2;
}

.line-clamp-3 {
   @extend .line-clamp;
   line-clamp: 3;
   -webkit-line-clamp: 3;
}

.cursor-pointer {
   cursor: pointer;
}

.container-sm-full {
   padding-inline: 0;

   @include sm {
      padding-inline: 1.5rem;
   }

   @include md {
      padding-inline: 2.5rem;
   }

   @include lg {
      padding-inline: 3.125rem;
   }

   @include xl {
      padding-inline: 4rem;
   }

   @include xxl {
      padding-inline: 5rem;
   }

   @include breakpoint(105rem) {
      width: calc(100% - 15rem);
      max-width: 105rem;
      padding-inline: 0;
   }
}

.container-md-full {
   padding-inline: 0;

   @include md {
      padding-inline: 2.5rem;
   }

   @include lg {
      padding-inline: 3.125rem;
   }

   @include xl {
      padding-inline: 4rem;
   }

   @include xxl {
      padding-inline: 5rem;
   }

   @include breakpoint(105rem) {
      margin-inline: auto;
      width: calc(100% - 15rem);
      max-width: 105rem;
      padding-inline: 0;
   }
}

.overflowing-slider {
   display: flex;
   overflow: auto;
   scroll-snap-type: x mandatory;
   width: max-content;
   scrollbar-width: none;
   -ms-overflow-style: none;

   &::-webkit-scrollbar {
      width: 0;
      height: 0;
   }

   &>.overflowing-slider-item {
      // scroll-snap-align: start;
      flex-shrink: 0;
   }
}

.positive {
   color: #119A41;
}

.negative {
   color: #E82127;
}

.min-width-zero {
   min-width: 0;
}

.custom-scroll-bar {
   &::-webkit-scrollbar {
      width: .325rem;
      height: .325rem;
   }

   &::-webkit-scrollbar-track {
      background: #f1f1f1;
   }

   &::-webkit-scrollbar-thumb {
      background: #cccccc;
   }
}